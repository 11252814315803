<template>
  <div>
    <Modal class="pop-product-properties" footer-hide v-model="showProperties">
      <div class="item item-solubility">
        <div class="item-title">
          Solubility
        </div>
        <div class="item-content">
          <div class="content-head">
            <div class="head-item">Claim</div>
            <div class="head-item">Solvent Medium
            </div>
            <div class="head-item">Amount of Solute (product)
            </div>
            <div class="head-item">Amount of Solvent (medium)
            </div>
          </div>
          <div class="content-body">
            <div class="body-line">
              <div class="body-item">
                test1
              </div>
              <div class="body-item">
                test2
              </div>
              <div class="body-item">
                test3
              </div>
              <div class="body-item">
                test4
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="item item-solubility">
        <div class="item-title">
          Percent Composition
        </div>
        <div class="item-content">
          <div class="content-head">
            <div class="head-item">Ingredient Input
            </div>
            <div class="head-item">% Composition
            </div>

          </div>
          <div class="content-body">
            <div class="body-line">
              <div class="body-item">
                test1
              </div>
              <div class="body-item">
                test2
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="item item-solubility">
        <div class="item-title">
          Particle Size
        </div>
        <div class="item-content">
          <div class="content-head">
            <div class="head-item">Mesh Screen Size

            </div>
            <div class="head-item">Analysis
            </div>
            <div class="head-item">% Yield

            </div>
          </div>
          <div class="content-body">
            <div class="body-line">
              <div class="body-item">
                test1
              </div>
              <div class="body-item">
                test2
              </div>
              <div class="body-item">
                test2
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="item item-solubility">
        <div class="item-title">
          Storage and Handling
        </div>
        <div class="item-content">
          <div class="content-head">
            <div class="head-item">Shelf Life
            </div>
            <div class="head-item">Recommended Storage Conditions

            </div>
            <div class="head-item">Packaging Configurations
            </div>
          </div>
          <div class="content-body">
            <div class="body-line">
              <div class="body-item">
                test1
              </div>
              <div class="body-item">
                test2
              </div>
              <div class="body-item">
                test 555
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="item item-solubility">
        <div class="item-title">
          Common Industries Served
        </div>
        <div class="item-content content-tag">
          Food & Beverage
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ['title','documents'],
  computed: {
  },
  components: {},
  filters: {
    shortName(val) {
      if (val.length > 40) return val.substring(0, 40) + "...";
      else return val;
    }
  },
  data() {
    return {
      showProperties:false
    };
  },
  mounted() {

  },
  filters: {},
  methods: {

  }
};
</script>
<style lang="less" scoped>
.pop-product-properties{
  .item{
    margin-bottom: 20px;
  }
  .content-tag{
    padding: 3px 10px;
    background: #cdffe4;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: #000;
  }
  .item-title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;

  }
  .content-head{
    display: flex;
    background: #f2f2f2;
    border-radius: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    .head-item{
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-weight: 500;
      font-size: 13px;
    }

  }
  .content-body .body-line{
    display: flex;

    .body-item{
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin-top:  7px
    }
  }
}
</style>
<style lang="less">
  .pop-product-properties{
    .ivu-modal {
      width: 800px !important;
      padding: 30px;
    }
  }
</style>
