<template>
  <div>
    <Modal class="pop-product-document" footer-hide v-model="showDocuments">
      <div class="top">
        <div class="product">{{title}}</div>
      </div>
      <div class="content">
         <div class="title">
           <div class="title-title">Document Title</div>
           <div class="title-type">Document Type</div>
           <div class="title-action">Action</div>
         </div>
        <div class="documents">
          <div class="item" v-for="(val,index) in documents" :key="index">
            <div class="documents-title">{{val.name | shortName}}</div>
            <div class="documents-type">{{val.extra && val.extra.type
              ? val.extra.type
              : "no type"
              }}</div>
            <div class="documents-action">
              <Icon type="md-download" />
              Download</div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ['title','documents'],
  computed: {
  },
  components: {},
  filters: {
    shortName(val) {
      if (val.length > 40) return val.substring(0, 40) + "...";
      else return val;
    }
  },
  data() {
    return {
      showDocuments:false
    };
  },
  mounted() {

  },
  filters: {},
  methods: {

  }
};
</script>
<style lang="less" scoped>
.pop-product-document {
  .top{
    .product{
      font-size: 19px;
      font-weight: 600;
    }

    margin-bottom: 15px;
  }
  .content{
    .title{
      display: flex;
      font-size: 15px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 7px;
      color: #757575;
      margin-bottom: 10px;
      .title-title{
        width: 50%;
      }
      .title-type{
        width: 30%;
      }
      .title-action{
        width: 20%;
      }
    }
    .documents{

      .item{
        display: flex;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 8px;
      }
      .documents-title{
        width: 50%;
      }
      .documents-type{
        width: 30%;
      }
      .documents-action{
        width: 20%;
        cursor: pointer;
      }
    }
  }

}


</style>
<style lang="less">
  .pop-product-document{
    .ivu-modal {
      width: 800px !important;
      padding: 30px;
    }
  }
</style>
