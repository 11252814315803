<template>
  <div>
    <Modal class="shows" v-model="shows" footer-hide>
      <div class="show-item">
        <div class="avatar">
          <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/jCb3CSEhqWRGGiZVuAGUTS.png" alt=""/>
        </div>
        <div class="title-and-status">
          <div class="status status-completed">Completed</div>
          <div class="title"> test test test test</div>
        </div>
        <div class="date">
          <div class="label">Date</div>
          <div class="val">2023-12-31 2024-01-12</div>
        </div>
        <div class="address">
          <div class="label">Address</div>
          <div class="val">address1 address1 address1 address1</div>
        </div>
      </div>

      <div class="show-item">
        <div class="avatar">
          <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/kubkiZ8RMYdyJVSPBSPza7.png" alt=""/>
        </div>
        <div class="title-and-status">
          <div class="status status-pending">Pending</div>
          <div class="title"> test test test test</div>
        </div>
        <div class="date">
          <div class="label">Date</div>
          <div class="val">2024-02-12 2024-02-15</div>
        </div>
        <div class="address">
          <div class="label">Address</div>
          <div class="val">address1 address1 address1 address1</div>
        </div>
      </div>

    </Modal>
  </div>
</template>

<script>

export default {
  props: [],
  computed: {

  },
  components: {  },

  data() {
    return {
      showsList: [],
      shows: false
    };
  },
  mounted() {

  },
  watch: {

  },
  filters: {

  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
  .shows{
    background: #fff;
    width: 600px;
    border-radius: 15px;
    .show-item{
      display: flex;
      justify-content: space-between;
      background: #efeefe;
      /*padding: 10px   15px;*/
      margin-bottom: 25px;
      border-radius: 15px;
      padding: 20px 10px;
      .avatar{
        img{
          width: 200px;
          height: 100%;
          border-radius: 10px;
        }
      }
      .title-and-status{
        margin-top: 10px;
        width: 180px;
        .status{
          font-size: 11px;
          padding: 3px 6px;
          border-radius: 15px;
          width: fit-content;
          color: #fff;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .status-pending{

          background: #71adce;
        }
        .status-completed{
          background: #b86aa4;
        }
        .title{
          font-size: 17px;
          font-weight: 600;
          color: #555;
        }
      }
      .date{
        margin-top: 10px;
        width: 160px;
        .label{
            font-size: 13px;
          color: #999;
        }
        .val{
          font-size: 15px;
          font-weight: 600;
          color: #777;
        }
      }
      .address{
        margin-top: 10px;
        width: 240px;
        .label{
          font-size: 13px;
          color: #999;
        }
        .val{
          font-size: 15px;
          font-weight: 600;
          color: #777;
        }
      }

    }

  }
</style>
<style lang="less">
  .shows {
    .ivu-modal {
      width: 900px !important;
      .ivu-modal-body{
        padding: 30px 30px;
      }
    }
  }
  </style>
