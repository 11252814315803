<template>
  <div class="homePage">
    <div class="sc-width">
      <div class="bottom-content d-flex d-flex-space-between">
        <div class="left">
          <div class="logo">
            <img :src="product ? product.images[0].url : ''" alt/>
          </div>
          <div class="left-part">
            <div class="left-part-item">
              <div class="left-part-item-word">
                QC Documents ({{product ? proDocs.length : ''}})
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item" @click="showDocumentation">
              <div class="left-part-item-word">
                Product Certification ({{product ? proCers.length : ''}})
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item">
              <div class="left-part-item-word">
                Factory Certification ({{product ? facCers.length : ''}})
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
          </div>
          <div class="left-part">
            <div class="left-part-item" @click="showPropertiesMethod">
              <div class="left-part-item-word">
                Properties
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item">
              <div class="left-part-item-word">
                Specifications
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item">
              <div class="left-part-item-word">
                Contaminants
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
          </div>
          <div class="left-part">
            <div class="left-part-item" @click="showPropertiesMethod">
              <div class="left-part-item-word">
                Literature
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item">
              <div class="left-part-item-word">
                Science
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item" @click="popShow()">
              <div class="left-part-item-word">
                Show
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item" @click="showWebinar()">
              <div class="left-part-item-word">
                Webinar
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item" @click="showTestImage()">
              <div class="left-part-item-word">
                Packaging
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
            <div class="left-part-item" @click="showFactoryImage()">
              <div class="left-part-item-word">
                Factory Images
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="mid">
          <div class="top-tips d-flex d-flex-space-between">
            <div class="tip-word">Sign in to create your own storefront now!</div>
            <div class="tip-btn">Log In</div>
          </div>
          <div class="product d-flex">
            <div class="product-name">{{product.marketing_name}}</div>
            <div class="product-icons d-flex">
              <div class="product-icons-item"></div>
              <div class="product-icons-item"></div>
              <div class="product-icons-item"></div>
            </div>
          </div>
          <div class="product-desc">Ascorbic acid is an essential vitamin and is known for working well with other enzymes and solutions. It may assist the body in building new tissue and help fortify the immune system. Ascorbic acid may help strengthen collagen, in synthesizing carnitine, and to support healthy metabolism. It may also help fortify the teeth and bones. Vitamin C is known for its antioxidant properties and may support cardiovascular health.</div>
          <div class="product-basic">
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Factory: </div>
                <div class="product-basic-item-value">{{product ? product.factory.factory_name : ''}}</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">Distributer: </div>
                <div class="product-basic-item-value cursor-pointer product-basic-item-value-active" @click="jumpTo('/company/'+product.company.id)">{{product ? product.company.company_name : ''}}</div>
              </div>
            </div>
          </div>
          <div class="product-line"></div>
          <div class="product-basic">
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Country:</div>
                <div class="product-basic-item-value">
                  <select @change="changeCountry($event)">
                    <option :value="country.id" v-for="(country) in countries"> {{country.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="product-basic-part product-basic-part-bottom">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Price:</div>
                <div class="product-basic-item-value">{{currentCountry.price}}</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">Inventory:</div>
                <div class="product-basic-item-value">{{currentCountry.inventory}}</div>
              </div>
            </div>

          </div>
          <div class="product-map">
            <GmapMap
              :center="{lat: (currentCountry.markers[0].position.lat), lng: (currentCountry.markers[0].position.lng)}"
              :zoom="1"
              map-type-id="roadmap"
              style="width: 100%; height: 100%"
            >
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              </gmap-info-window>
              <GmapMarker
                :key="index"
                v-for="(m, index) in currentCountry.markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                :icon="require('@/assets/icons/factory.png')"
                @click="toggleInfoWindow(m,index)"
              />
            </GmapMap>
          </div>
          <div class="product-line"></div>

          <div class="faq" id="vital1">
            <div class="faq-title d-flex">
              <div class="faq-title-icon">
                <img src="@/assets/product/faq.svg" alt=""/>
              </div>
              <div class="faq-title-name">FAQ</div>

            </div>
            <div class="faq-content">
              <div class="faq-content-item">
                <div class="faq-content-label">1.Question 1 Question 1 Question 1 Question 1 Question 1 Question 1</div>
                <div class="faq-content-value">answer 2323232323 Question2323232323 Question2323232323</div>
              </div>
              <div class="faq-content-item">
                <div class="faq-content-label">2.Question 2 Question 2 Question 2 Question 2 </div>
                <div class="faq-content-value">answer 2323232323 Question23233232323</div>
              </div>
            </div>
          </div>

          <div class="test" style="display: none">
            <viewer :images="testImagList">
              <img v-for="src in testImagList" :key="src" :src="src">
            </viewer>
          </div>
          <div class="factory-image" style="display: none">
            <viewer :factoryImages="factoryImgList">
              <img v-for="src in factoryImgList" :key="src" :src="src">
            </viewer>
          </div>

        </div>
        <div class="right">
          <div class="contact">
            <div class="seller">
              <div class="seller-logo">
                <img :src="product.company.first_user.avatar.url" alt=""/>
              </div>
              <div class="seller-info">
                <div class="seller-name">John</div>
                <div class="seller-title">Sale</div>
              </div>
            </div>
            <div class="seller-btn">Contact</div>

          </div>

          <div class="right-line"></div>
          <div class="right-btns">
            <div class="right-btn">
              <div class="right-btn-word">Sample</div>
              <div class="right-btn-icon"></div>
            </div>
            <div class="right-btn" >
              <div class="right-btn-word">Price</div>
              <div class="right-btn-icon"></div>
            </div>
            <div class="right-btn" @click="showPrice">
              <div class="right-btn-word">Place Orders</div>
              <div class="right-btn-icon"></div>
            </div>
            <div class="right-btn place-order" v-if="showPlaceOrder">
              <div class="order-input">
                <input type="text" placeholder="Order Quantity" />
              </div>
              <div class="order-submit">Submit</div>
            </div>
            <div class="right-btn ">
              <div class="right-btn-word">RFQ</div>
              <div class="right-btn-icon"></div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ProductDocuments ref="productDocuments" :title="123"></ProductDocuments>
    <ProductProperties ref="productProperties" :title="123"></ProductProperties>
    <Show ref="show" :title="123"></Show>
    <Webinar ref="webinar" :title="123"></Webinar>
  </div>
</template>

<script>
  import api from "@/api";
  import { mapState } from "vuex";
  import Askquestion from "@/components/askquestion";
  import PopLogin from "@/components/popLogin";
  import ProductDocuments from "@/components/ProductDocuments";
  import ProductProperties from "@/components/ProductProperties";
  import Show from "@/components/show";
  import Webinar from "@/components/webinar";
  // import truncate from "vue-truncate-collapsed";
  import { Chart } from "@antv/g2";
  import truncate from "vue-truncate-collapsed";

  // import { MatchMedia } from "vue-component-media-queries";
  const {
    _queryProduct,
    _countDownload,
    _exchangeBusinessCard,
    _queryCompanyProduct,
    _deleteFavorite,
    _createFavorite
  } = api;
  import "swiper/swiper-bundle.min.css";

  import Swiper from "swiper";

  export default {
    components: {
      // MatchMedia,
      truncate,
      Askquestion,
      PopLogin,ProductDocuments,ProductProperties,Show,Webinar
    },
    filters: {
      shortName(val) {
        if (val.length > 40) return val.substring(0, 40) + "...";
        else return val;
      }
    },
    created() {
      const id = this.$route.params.id;
      this.id = id;
      if (this.$route.query.type) {
        this.activeTab = this.$route.query.type;
      }

      this.loading = true;
      _queryProduct({ id })
        .then(response => {
          const { data } = response;
          this.product = data;
          this.companyId = this.product.company.id;
          this.is_exchanged_business_card = this.product.company.is_exchanged_business_card;
          sessionStorage.setItem(
            "prodcutDocuments",
            JSON.stringify(data.documents)
          );

          if (!this.product.property) {
            this.product.property = {};
          }

          var warehousesAll =
            data.warehouses &&
            ((data.warehouses && data.warehouses.length === 0) ||
              (data.warehouses.length && !data.warehouses[0].city));
          if (!data.warehouses || warehousesAll) this.tabs[5] = "";

          var contaminantsAll =
            data.contaminant &&
            ((data.contaminant.microbial_contaminants_breakdown &&
              data.contaminant.microbial_contaminants_breakdown.length &&
              data.contaminant.microbial_contaminants_breakdown[0].name) ||
              (data.contaminant.heavy_metals_breakdown &&
                data.contaminant.heavy_metals_breakdown.length &&
                data.contaminant.heavy_metals_breakdown[0].test_method));
          if (!contaminantsAll) this.tabs[4] = "";

          var specificationsAll =
            data.specification &&
            ((data.specification.assay_claims.length &&
              data.specification.assay_claims[0].assay_analyte) ||
              data.specification.chemical_formula ||
              data.specification.molecular_weight ||
              data.specification.processing_method ||
              data.specification.starting_material ||
              data.specification.solvents_used_in_production);
          if (!specificationsAll) this.tabs[3] = "";

          var propertiesAll =
            data.property.description ||
            (data.property.appearance &&
              data.property.appearance.length &&
              data.property.appearance[0].color) ||
            (data.property.solubility &&
              data.property.solubility.length &&
              data.property.solubility[0].claim) ||
            (data.property.composition_percent &&
              data.property.composition_percent.length &&
              data.property.composition_percent[0].ingredient) ||
            (data.property.particle_size &&
              data.property.particle_size.length &&
              data.property.particle_size[0].mesh_screen_size) ||
            (data.property.storage_and_handling &&
              data.property.storage_and_handling.shelf_life);
          if (!propertiesAll) this.tabs[2] = "";

          if (!data.documents || (data.documents && data.documents.length === 0))
            this.tabs[1] = "";

          // Analysis
          if (!data.prices || (data.prices && data.prices.length === 0))
            this.tabs[6] = "";

          this.tabs = this.tabs.filter(function(e) {
            return e;
          });

          // if (!this.role) {
          //   this.tabs = [
          //     {
          //       name: "Basic Info",
          //       value: "basicInfo"
          //     }
          //   ];
          // }

          this.proDocs = data.documents?.filter(
            item => item.type === "Product Documents"
          );
          this.proCers = data.documents?.filter(
            item => item.type === "Product Certification"
          );
          this.facCers = data.factory?.document?.filter(
            item => item.type === "Factory Certification"
          );
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.logos = this.product.images;
          this.initSwiper2();
          this.queryCompanyProduct();
          // this.logoUrl = this.product.avatar[this.logoKey].url
        });

      // _queryProductPrice({ id }).then(response => {
      //   const { data } = response;
      //   this.priceLists = data.data;
      //   // this.priceLists.forEach((val, index) => {
      //   //   this.$set(this.priceLists[index], "price", "$" + val.price);
      //   // });
      // });
    },
    computed: {
      ...mapState("user", ["token", "role"])
    },
    data() {
      return {
        testImagList: [
          'https://images.unsplash.com/photo-1615485500834-bc10199bc727?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBvd2RlcnxlbnwwfHwwfHx8MA%3D%3D',
          'https://images.unsplash.com/photo-1586137712370-9b450509c587?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
          'https://images.unsplash.com/photo-1633509907796-ece8a21bdbcb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        ],
        factoryImgList : [
          "https://images.unsplash.com/photo-1557064262-6acc904bffbd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZmFjdG9yeXxlbnwwfHwwfHx8MA%3D%3D",
          "https://images.unsplash.com/photo-1513828583688-c52646db42da?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGZhY3Rvcnl8ZW58MHx8MHx8fDA%3D",
          "https://images.unsplash.com/photo-1547895749-888a559fc2a7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGZhY3Rvcnl8ZW58MHx8MHx8fDA%3D"

        ],
        mobileWidth: window.innerWidth < 840,
        curAssDoc: sessionStorage.getItem("associatedDoc"),
        loading: false,
        exchangeBusinessCardLoading: false,
        showExchange: false,
        btnloading: false,
        product: {},
        proDocs: [],
        proCers: [],
        facCers: [],
        curItemNum: 0,
        showVideo: false,
        tabs: [
          {
            name: "Basic Info",
            value: "basicInfo"
          },
          {
            name: "Documentation",
            value: "documentation"
          },
          {
            name: "Properties",
            value: "properties"
          },
          {
            name: "Specifications",
            value: "specifications"
          },
          {
            name: "Contaminants",
            value: "contaminants"
          },
          {
            name: "Warehouses",
            value: "warehouses"
          },
          {
            name: "Analysis",
            value: "analysis"
          }
        ],
        activeTab: "basicInfo",
        ansLoading: true,
        priceLists: [],
        swiper: "",
        swiper1: "",
        companyId: "",
        companyProduct: [],
        logoUrl: "",
        logoKey: 0,
        logos: [],
        time: null,
        swiperInit: true,
        swiper2Init: true,
        productCount: 0,
        searchProductValue: "",
        showSellerInfo: false,
        is_exchanged_business_card: false,
        showPopLogin: false,
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
        },

        countries:[
          {
            id:1,
            name: 'United States',
            price: '$120(CFR)',
            inventory: '2600KG',
            markers: [{
              position: {
                lat: 36.1,
                lng: -118.3
              },
              infoText: '<strong>test 2</strong>'
            },
          ]
          },
          {
            id:4,
            name: 'China',
            price: '$100(CIR)',
            inventory: '1600KG',
            markers: [{
              position: {
                lat: 35.1,
                lng: 114.0
              },
              infoText: '<strong>test 223333</strong>'
            },
            ]
          },
          {
            id:6,
            name: 'India',
            price: '$180(CIR)',
            inventory: '1950KG',
            markers: [{
              position: {
                lat: 18.0,
                lng: 72.0
              },
              infoText: '<strong>test 2</strong>'
            },
              {
                position: {
                  lat: 11.0,
                  lng: 11.0
                },
                infoText: '<strong>test 3</strong>'
              }]
          }
        ],
        currentCountry: {},
        markers: [
          {
            position: {
              lat: 10.0,
              lng: 10.0
            },
            infoText: '<strong>test 2</strong>'
          }, {
            position: {
              lat: 11.0,
              lng: 11.0
            },
            infoText: '<strong>test 3</strong>'
          }
        ],
        showPlaceOrder: false
      };
    },
    mounted() {
      this.$nextTick(() => {
        // this.initSwiper()
      });
      // this.timer = setInterval(() => {
      //   if(this.logoKey > this.logos.length){
      //     this.logoKey = 0
      //   }else{
      //     this.logoKey = Number(this.logoKey) + (1)
      //   }
      //   this.logoUrl = this.product.avatar[this.logoKey].url
      // }, 5000)
      this.currentCountry = this.countries[0];
    },
    methods: {
      showTestImage() {
        this.$viewerApi({
          images: this.testImagList,
        })
      },
      showFactoryImage() {
        this.$viewerApi({
          images: this.factoryImgList,
        })
      },
      jumpTo(path){
        this.$router.push(path);
      },
      goAnchor(selector) {
        document.querySelector(selector).scrollIntoView({
          behavior: "smooth"
        });
      },
      showPrice(){
        this.showPlaceOrder = !this.showPlaceOrder;
      },
      showPropertiesMethod(){
        this.$refs.productProperties.showProperties = true;
      },
      showWebinar(){
        this.$refs.webinar.showWebinar = true;
      },
      popShow(){
        this.$refs.show.shows = true;
      },
      changeCountry($e){
        this.countries.forEach(item => {
          if(item.id.toString() === $e.target.value){
            this.currentCountry = item;
          }
        });
      },
      showDocumentation(){
        this.$refs.productDocuments.showDocuments = true;
        this.$refs.productDocuments.documents = this.proCers;
        this.$refs.productDocuments.title = 'Product Certification';
      },
      toggleInfoWindow: function(marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      },
      favorite(product) {
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
        } else {
          if (this.product.is_favorite) {
            this.product.is_favorite = false;
            _deleteFavorite({ id: product.id, type: 0 });
          } else {
            this.product.is_favorite = true;
            _createFavorite({ related_id: product.id, type: 0 });
          }
        }
      },
      upFirstLetter(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      },
      requestQuote() {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        this.$router.push(
          `/requests/create?id=${
            this.product.id
            }&pro=${this.product.marketing_name.replace(/%/g, "%25")}&fac=${this
            .product.factory && this.product.factory.factory_name}&addr=${(this
            .product.company.company_street_address || "") +
          " " +
          (this.product.company.company_city_address || "") +
          " " +
          (this.product.company.company_state_address || "") +
          " " +
          this.product.company.company_country_address_desc}`
        );
      },
      message(companyId, userId) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        if (this.is_exchanged_business_card) {
          this.$router.push("/chat?company=" + companyId + "&user=" + userId);
        } else {
          this.showExchange = true;
        }
      },
      searchProduct(companyId) {
        this.$router.push({
          path: "/company/" + companyId,
          query: { type: "product", s: this.searchProductValue }
        });
      },
      viewAllProduct(companyId) {
        this.$router.push({
          path: "/company/" + companyId,
          query: { type: "product" }
        });
      },
      viewProduct(item) {
        if (item.redirect_url) {
          window.open(item.redirect_url);
        } else {
          this.$router.push(`/products/` + item.id);
        }
      },
      swiperChange(index) {
        console.log(index);
        this.logoUrl = this.logos[index].url;
        this.swiper2Init = false;
      },
      prev3() {
        this.swiper3.slidePrev();
      },
      next3() {
        this.swiper3.slideNext();
      },
      prev2() {
        this.swiper2.slidePrev();
        // this.swiper2Init = false;
      },
      next2() {
        this.swiper2.slideNext();
        // this.swiper2Init = false;
      },
      navJump(path) {
        if ("home" == path) {
          this.$router.push("/index");
        }
        if ("profile" == path) {
          this.$router.push(`/company/${this.companyId}`);
        }
      },
      initSwiper() {
        this.swiper = new Swiper(".swiper", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next1",
            prevEl: ".prev1"
          }
        });
      },
      initSwiper2() {
        this.swiper2 = new Swiper(".swiper2", {
          autoplay: {
            delay: 10000
          },
          loop: true,
          slidesPerView: 1,
          spaceBetween: 0,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next2",
            prevEl: ".prev2"
          }
        });
        // console.log('ppp')
        // this.prev2()
      },
      initSwiper3() {
        this.swiper = new Swiper(".swiper3", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 6,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next3",
            prevEl: ".prev3"
          }
        });
      },
      prev() {
        this.swiper.slidePrev();
      },
      next() {
        this.swiper.slideNext();
      },
      prev1() {
        this.swiper1.slidePrev();
      },
      next1() {
        this.swiper1.slideNext();
      },
      initSwiper1() {
        this.swiper1 = new Swiper(".swiper1", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "vertical",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1"
          }
        });
      },
      showQues() {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        if (this.product.company.is_exchanged_business_card) {
          this.$refs.askQ.showAskques = true;
        } else {
          this.showExchange = true;
        }
      },
      exchangeBusinessCard(type) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if (!this.role) {
          this.$router.push("/user-action/login");
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        this.btnloading = true;
        this.exchangeBusinessCardLoading = true;
        _exchangeBusinessCard({
          id: this.product.company.id
        })
          .then(() => {
            // console.log(response);
            this.product.company.is_exchanged_business_card = true;
            // this.$Notice.open({
            //   title: `You have exchanged business cards with ${this.company_name}.`
            // });

            if (type === "ques") {
              this.showExchange = false;
              this.showInfo();
            }
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {
            this.btnloading = false;
            this.showExchange = false;
            this.exchangeBusinessCardLoading = false;
            this.$refs.askQ.showAskques = true;
          });
      },
      drawChart() {
        const chart = new Chart({
          container: "analysisChart",
          autoFit: true,
          height: 300,
          padding: [20, 40, 20, 40]
        });

        // chart.data(this.priceLists);
        // chart.scale({
        //   price_date: {
        //     range: [0, 1]
        //   },
        //   price: {
        //     min: 0,
        //     nice: true
        //   }
        // });

        chart.data(this.product.prices);
        chart.scale({
          price_date: {
            range: [0, 1]
          },
          price: {
            min: 0,
            nice: true
          }
        });

        chart.axis("price", {
          label: {
            formatter: val => {
              return "$" + val; // edit
            }
          }
        });

        chart
          .line()
          .position("price_date*price") // 数据源（一个数组）中每个元素（一个对象）的month属性和temperature属性交会画出来的折线
          .tooltip("price_date*price", function(price_date, price) {
            // tooltip的第一个参数写上需要显示的字段'item1*item2*...'；第二个参数为一个函数，该函数的参数为需要显示的字段。
            return {
              name: "price",
              value: "$" + price
            };
          });

        // chart.line().position("price_date*price");
        // .label("price", xValue => {
        //   return {
        //     content: "$" + xValue
        //   };
        // });
        chart.point().position("price_date*price");

        chart.render();
        // chart.repaint(); //重绘
      },
      downloadFile(item) {
        window.open(item.url);
        _countDownload({
          id: item.id
        });
      },
      showInfo() {
        this.$Notice.info({
          title: "Business card has been exchanged."
        });
      },
      cancel() {
        this.showExchange = false;
      },
      // navJump(type){
      //   if('product' === type){
      //
      //   }
      //   if('profile' === type){
      //     this.$router.push('/test-product-list');
      //   }
      // }
      queryCompanyProduct() {
        _queryCompanyProduct({
          id: this.companyId,
          page: 1,
          limit: 30
        })
          .then(response => {
            const { data } = response;
            this.companyProduct = data.data;
            this.productCount = data.total;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            // this.initSwiper();
            this.initSwiper3();
          });
      }
    },
    watch: {
      activeTab(nval) {
        if (nval === "analysis") {
          setTimeout(() => {
            this.ansLoading = false;
            this.drawChart();
          }, 500);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .homePage{
    margin-top: 100px;
    background: #fff;
    .sc-width{
      margin: 0 auto;
      padding-bottom: 30px;
    }
  }
  .d-flex{
    display: flex;
  }
  .d-flex-space-between{
    justify-content: space-between;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .top-tips{
    height: 44px;
    background: #222C2E;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    color: #fff;
    margin-bottom: 18px;
    .tip-btn{
      background: #f4f4f4;
      border-radius: 5px;
      cursor: pointer;
      color: #222c2e;
      display: flex;
      justify-content: center;
      padding: 2px 10px;
      font-weight: 500;
    }
  }
  .left{
    width: 285px;
    background-image: linear-gradient(130deg, #c3caf6, #ede0f9);
    padding: 30px 20px;
    height: fit-content;
    border-radius: 10px;
    .logo{
      display: flex;
      justify-content: center;
      img{
        width: 175px;
        height: 135px;
        border-radius: 5px;
      }
    }
    .left-part{
      border-radius: 10px;
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 15px;
      .left-part-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 15px;
        cursor: pointer;
        &:hover{
          background: #f4f4f4;
        }
        .left-part-item-word{
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .mid{
    width: 900px;
    .product{
      .product-name{
        font-size: 26px;
        font-weight: 600;
        color: #000;
      }
      .product-icons{

      }
    }
    .product-desc{
      font-size: 15px;
      color: #000;
      font-weight: 400;
      margin: 10px 0 25px 0;
    }
    .product-basic{
      .product-basic-part{
        width: 100%;
        display: flex;
      }
      .product-basic-part-bottom{
        margin: 10px 0;
      }
      .product-basic-item{
        width: 50%;
        display: flex;
        .product-basic-item-label{
          font-weight: 500;
          font-size: 16px;
          margin-right: 5px;
          color: #000;
        }
        .product-basic-item-value{
          font-size: 16px;
          color: #000;
          select{
            border-radius: 5px;
            padding: 3px 6px;
            position: relative;
            bottom: 4px;
          }
        }
        .product-basic-item-value-active{
          color: #FF4835;
          font-weight: 500;
        }
      }
    }
    .product-map{
      width: 100%;
      height: 350px !important;
    }
    .product-line{
      height: 1px;
      background: #e3e3e3;
      margin: 15px auto;
    }
    .faq{
      .faq-title{

        .faq-title-icon{
          margin-right: 10px;
          img{
            width: 28px;
            height: 28px;
          }
        }
        .faq-title-name{
          font-weight: 500;
          font-size: 18px;
          color: #000;
        }
      }
      .faq-content{
        .faq-content-item{
          margin: 10px 0;
          .faq-content-label{
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 3px;
            color: #000;
          }
          .faq-content-value{
            color: #000;
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }
  }
  .right{
    width: 300px;
    padding: 30px 30px;
    box-shadow: 0 12px 20px #E3E3E3, 0 4px 8px #E3E3E3, 0 0 16px #E3E3E3;
    margin-top: 20px;
    border-radius: 10px;
    height: fit-content;
    .contact{
      .seller{
        display: flex;
        .seller-logo{
          margin-right: 10px;
          img{
            width: 65px;
            height: 65px;
            border-radius: 50%;
          }
        }
        .seller-name{
          font-weight: 500;
          font-size: 17px;
        }
      }
      .seller-btn{
        background: #FF4835;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        &:hover{
          opacity: 0.85;
        }
      }

    }
    .right-line{
      height: 1px;
      background: #e3e3e3;
      margin: 13px auto;
    }
    .right-btns{
      .right-btn{
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin-bottom: 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        &:hover{
          background: #f4f4f4;
        }
      }
    }
  }
  .place-order{
    display: flex !important;
    justify-content: space-between !important;
    border: none !important;
    input{
      width: 150px;
      height: 40px;
      border: 1px solid #cccccc;
      border-radius: 10px;
      padding-left: 10px;
      &:focus{
        outline: none;
      }
    }
    .order-submit{
      padding: 5px 12px;
      background: #fff;
      border: 1px solid #cccccc;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>

<style lang="less">

</style>
